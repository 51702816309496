<template>
  <section id="FlightScheduleIndexSection">
    <vue-headful title="FLPN Aviasolutions - Flight Schedule"></vue-headful>
    <h1 class="title">Flight Schedule</h1>
    <div class="columns">
      <div class="column is-12 is-full-mobile fixedleft">
        <div class="card">
          <div class="card-content">
            <form action="#" autocomplete="off" v-on:submit.prevent="paginate">
              <div class="columns" style="margin-bottom:10px;">
                <div class="column is-2">
                  <b-field label="Begining">
                    <datepicker placeholder="Select a day" :config="{ dateFormat: 'm/d/Y', static: true }"
                                v-model="date"></datepicker>
                  </b-field>
                </div>
                <div class="column is-2">
                  <PaginationResults v-model="pagination.perPage" />
                </div>
                <div class="column is-3">
                  <label class="label">&nbsp;</label>
                  <button type="submit" class="button is-link is-fullwidth">
                                        <span class="icon">
                                        <i class="fas fa-filter"></i>
                                        </span>
                    <span class="has-text-weight-bold is-uppercase">Filter Scheduled Flights</span>
                  </button>
                </div>
                <div class="column is-2">
                </div>
                <div class="column is-3 is-fullwidth" v-if="$auth.check(['admin-client', 'admin'])">
                  <label class="label">&nbsp;</label>
                  <router-link :to="'/flight-schedule/define'" class="button is-link is-fullwidth">
                                        <span class="icon">
                                        <i class="fas fa-plus"></i>
                                        </span>
                    <span class="has-text-weight-bold is-uppercase">Define a Flight Schedule</span>
                  </router-link>
                </div>
              </div>
            </form>
            <b-table
                paginated
                backend-pagination
                backend-sorting
                :hoverable="true"
                :data="pagination.records"
                :loading="pagination.loading"
                :total="pagination.total"
                :per-page="pagination.perPage"
                :row-class="(row, index) => row.is_selected === true && 'is-info'"
                @page-change="onPageChange"
                @sort="onSort">
              <template slot-scope="props">
                <b-table-column width="80" label="Generate Plan" v-if="$auth.check(['admin', 'admin-client'])">
                  <div class="field has-addons">
                    <p class="control">
                      <router-link :to="`/flight-schedule/${props.row.id}/create-plan`"
                                   class="button is-small is-link is-success" :class="{'is-warning': props.row.has_plans_in_day}">
                        GENERATE PLAN
                      </router-link>
                    </p>
                  </div>
                </b-table-column>
                <b-table-column field="flightNumber" label="Flight Number">
                  <span>{{ props.row.flightNumber }}</span>
                </b-table-column>
                <b-table-column field="etd" label="ETD">
                  <span>{{ props.row.etd }}</span>
                </b-table-column>
                <b-table-column field="eta" label="ETA">
                  <span>{{ props.row.eta }}</span>
                </b-table-column>
                <b-table-column field="fleet" label="Fleet">
                  <span>{{ props.row.fleet.name }}</span>
                </b-table-column>
                <b-table-column field="route" label="Route">
                  <span>{{ props.row.route.departure }} - {{ props.row.route.destination }}</span>
                </b-table-column>
                <b-table-column field="flightLevel" label="Flight Level">
                  <span>{{ props.row.flightLevel }}</span>
                </b-table-column>
                <b-table-column field="alternative routes" label="Alt. Route(s)">
                                    <span>
                                      {{
                                        props.row.firstAlternateRoute.departure
                                      }} - {{ props.row.firstAlternateRoute.destination }}
                                      <span v-if="props.row.secondAlternateRoute">
                                        &nbsp;/&nbsp;{{
                                          props.row.secondAlternateRoute.departure
                                        }} - {{ props.row.secondAlternateRoute.destination }}
                                      </span>
                                    </span>
                </b-table-column>
                <b-table-column field="altfl" label="Alt. FL">
                                    <span>
                                      {{ props.row.alternate_route_1_flight_level }}
                                      <span v-if="props.row.alternate_route_2_flight_level">&nbsp;/&nbsp;{{
                                          props.row.alternate_route_2_flight_level
                                        }}</span>
                                    </span>
                </b-table-column>
                <b-table-column width="80" label="Edit/Delete" v-if="$auth.check(['admin-client', 'admin'])">
                  <div class="field has-addons">
                    <p class="control">
                      <router-link :to="`/flight-schedule/${props.row.id}/edit`"
                                   class="button is-small is-link is-success">
                        EDIT
                      </router-link>
                    </p>
                    <p class="control">
                      <b-tooltip label="Delete" type="is-dark">
                        <button
                            type="button"
                            class="button is-danger is-small"
                            v-on:click="onDeleteSchedule(props.row)"
                        >
                          <i class="fas fa-minus-circle"></i>
                        </button>
                      </b-tooltip>
                    </p>
                  </div>
                </b-table-column>
              </template>
            </b-table>
            <article v-if="pagination.total == 0" class="message is-warning">
              <div class="message-body">
                No scheduled flights for {{ date }}
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>
<script>
import ScheduledFlightsService from "../../services/scheduled-flights.service";
import Datepicker from 'vue-bulma-datepicker'
import PaginationResults from "@/components/shared/PaginationResults";

export default {
  name: "ScheduledFlightIndex",
  data() {
    return {
      isLoading: false,
      scheduledFlightsService: new ScheduledFlightsService(),
      date: null,
      pagination: {
        records: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 15
      },
    };
  },
  methods: {
    paginate() {
      const params = {
        per_page: this.pagination.perPage,
        page: this.pagination.page,
        date: this.date
      };
      this.pagination.loading = true;
      this.scheduledFlightsService.paginate(params).then(
          ({data}) => {
            this.pagination.records = data.data;
            // let currentTotal = data.total

            // if (data.total / this.pagination.perPage > 1000) {
            //     currentTotal = this.pagination.perPage * 1000
            // }
            this.pagination.total = data.meta.total;
            // data.records.forEach((record) => {
            //     this.pagination.records.push(record)
            // })
            this.pagination.loading = false
          },
          () => {
            this.pagination.records = []
            this.pagination.total = 0
            this.pagination.loading = false
            this.$buefy.toast.open({
              message: 'An error ocurred while paginating!!!',
              position: 'is-bottom',
              type: 'is-danger'
            });
          }
      );
    },
    generateFilters() {
      var filtro = {};
      if (this.filter.hasValue(this.filter.date)) {
        filtro.date = this.filter.date;
      }
      return filtro;
    },
    /*
    * Handle page-change event
    */
    onPageChange(page) {
      this.pagination.page = page;
      this.paginate();
    },
    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.pagination.sortField = field;
      this.pagination.sortOrder = order;
      this.paginate();
    },
    onDeleteSchedule(scheduledFlight) {
      let days = this.getDays(scheduledFlight);
      this.$buefy.dialog.confirm({
        title: "Deleting Scheduled Flight",
        message: `This will <b>delete</b> the schedule that<br><b>begins on</b>: ${scheduledFlight.begining}<br>and <b>ends on</b>: ${scheduledFlight.end}</br>(${days})<br>from the database, <b>are you sure?</b>`,
        cancelText: "Cancel",
        confirmText: "Yes, delete",
        type: "is-danger",
        size: "is-medium",
        // width: 600,
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({container: null})
          this.scheduledFlightsService.delete(scheduledFlight.id).then(
              ({data}) => {
                loadingComponent.close();
                this.paginate();
                this.$buefy.toast.open({
                  message: data.notificacion,
                  type: "is-success"
                });
              },
              () => {
                loadingComponent.close();
                this.paginate();
                this.$buefy.toast.open({
                  message: "An error ocurred while deleting the scheduled flight.",
                  type: "is-danger"
                });
              }
          );
        }
      });
    },
    getDays(scheduledFlight) {
      let days = []
      if (scheduledFlight.monday) {
        days.push('mon');
      }
      if (scheduledFlight.tuesday) {
        days.push('tue');
      }
      if (scheduledFlight.wednesday) {
        days.push('wed');
      }
      if (scheduledFlight.thursday) {
        days.push('thu');
      }
      if (scheduledFlight.friday) {
        days.push('fri');
      }
      if (scheduledFlight.saturday) {
        days.push('sat');
      }
      if (scheduledFlight.sunday) {
        days.push('sun');
      }
      return days.join(', ');
    }
  },
  mounted() {
    const date = new Date();
    this.date = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    this.paginate();
  },
  components: {
    PaginationResults,
    Datepicker
  },
};
</script>
<style>
tr.is-info {
  background: #167df0;
  color: #fff;
}

#FlightScheduleIndexSection .card-header {
  background-color: lightgrey;
}
</style>